import {
  AdyenPlatformExperience,
  CapitalOverview,
  DevEnvironment,
} from "@adyen/adyen-platform-experience-web";
import { useParams } from "react-router-dom";
import { getAdyenComponentSessionBody } from "../services/transactionsService";
import { useEffect, useRef } from "react";
import useCurrentUserAccount from "../hooks/data/queries/useCurrentUserAccount";

const adyenComponentsEnv: DevEnvironment =
  (process.env.REACT_APP_ADYEN_COMPONENTS_ENV as DevEnvironment) ?? "test";

type CapitalParams = { accountHolderCode: string };

async function getAdyenPlatformExperience(accountHolderCode: string) {
  return await AdyenPlatformExperience({
    locale: "en-US",
    environment: adyenComponentsEnv,
    async onSessionCreate() {
      const sessionData = await getAdyenComponentSessionBody({
        allowOrigin: process.env.REACT_APP_BASE_URI?.slice(0, -1) ?? "",
        accountHolderId: accountHolderCode,
        roles: ["Capital Component: Manage"],
      });

      return sessionData;
    },
  });
}

function Capital(): JSX.Element {
  const { accountHolderCode } = useParams<CapitalParams>();
  const { data: currentUserAccountData } = useCurrentUserAccount();
  const capitalOverviewRef = useRef<HTMLDivElement | null>(null);

  const showComponent =
    currentUserAccountData?.type === "CSR" ||
    accountHolderCode === currentUserAccountData?.accountHolderCode;

  async function mountCapitalComponent(node: HTMLElement) {
    const capitalOverview = new CapitalOverview({
      core: await getAdyenPlatformExperience(accountHolderCode ?? ""),
    });
    capitalOverview.mount(node);
  }

  useEffect(() => {
    mountCapitalComponent(capitalOverviewRef.current as HTMLElement);
  });

  return (
    <>
      {showComponent && (
        <div ref={capitalOverviewRef} className="flex w-full justify-center" />
      )}
    </>
  );
}

export default Capital;
