import { SortingState } from "@tanstack/react-table";
import {
  DefaultListResponse,
  DefaultPostBody,
  FilterType,
  OrderType,
  SortingType,
} from "../types";
import { getOnboardingStatus } from "../utils/accounts";
import { formatAmount } from "../utils/currency";
import {
  formatISODateToLocal,
  longDateFormat,
  reportDateFormat,
} from "../utils/dateTime";
import { httpCall } from "../utils/http";
import { getCurrentUserAccountWithExtraFields } from "../utils/userAccounts";
import { isNullOrEmpty } from "../utils/string";
import { PageFilter, SortFilter } from "../atoms";

export type CreateAccountHolderBody = {
  accountHolderCode: string;
  accountHolderDetails: {
    address: {
      country: string;
      timeZoneCode: string;
    };
    businessDetails: {
      legalBusinessName: string;
      doingBusinessAs: string;
      shareHolders: [
        {
          name: {
            firstName: string;
            lastName: string;
          };
          address: {
            country: string;
            timeZoneCode: string;
          };
          email: string;
        }
      ];
    };
    email: string;
  };
  legalEntity: string;
  merchantAccount: string;
  merchantAccountId: string;
};

export type CreateAccountHolderResponse = {
  accountHolderCode?: string;
  Message?: string;
  StatusCode?: number;
};

export type CreateAccountHolderBalance = {
  edition: string;
  jackrabbitId: string;
  country: string;
  timeZone: string;
  balancePlatform: string;
  description: string;
  firstName: string;
  lastName: string;
  companyEmail: string;
  phone: string;
  legalEntity: {
    type: number;
    organization: {
      legalName: string;
      registrationNumber: string;
      type: string;
      registeredAddress: {
        city: string;
        country: string;
        postalCode: string;
        stateOrProvince: string;
        street: string;
        street2: string;
      };
    } | null;
    individual: {
      name: {
        firstName: string;
        lastName: string;
      };
      email: string;
      residentialAddress: {
        city: string;
        country: string;
        postalCode: string;
        stateOrProvince: string;
        street: string;
        street2: string;
      };
    } | null;
    soleProprietorship: {
      name: string;
      countryOfGoverningLaw: string;
      registeredAddress: {
        city: string;
        country: string;
        postalCode: string;
        stateOrProvince: string;
        street: string;
        street2: string;
      };
    } | null;
  };
  store: {
    description: string;
    shopperStatement: string;
    phoneNumber: string;
    reference: string;
    address: {
      city: string;
      country: string;
      postalCode: string;
      stateOrProvince: string;
      line1: string;
    };
  };
};

export type CreateAccountHolderBalanceResponse = {};

type Timezone = {
  code: string;
  country: string;
  name: string;
};

type AccountHolderStore = {
  storeId: string;
  businessLineId: string;
  accountHolderId: string;
  storeReference: string;
  description: string;
  shopperStatement: string;
  status: string;
  businessLine: string;
};

export type AccountHolder = {
  edition: string;
  jrId: string;
  adyenAccountHolderCode: string;
  legalEntityType: string;
  companyName: string;
  companyEmail: string;
  contactFirstName: string;
  contactLastName: string;
  country: string;
  adyenStatus: string;
  jrPayStatus: string;
  legalBusinessName: string;
  id: string;
  contactFullName: string;
  onboardingStatus: OnboardingStatus;
  locations?: string;
  ratePlans: AccountHolderRatePlan[];
  paymentProcessingState: boolean;
  payoutState: boolean;
  adyenMerchantAccount: string;
  adyenPayoutSchedule: any;
  accounts: Account[];
  shopperStatement: string;
  convFee: ConvFee;
  convFeeRate: string;
  techFeeRate: string;
  allowedPaymentMethods: PaymentMethod[];
  merchantStatus: string;
  adyenCaptureDelay: string;
  accountHolderNotes: AccountHolderNote[];
  dailySubscription: boolean | null;
  monthlySubscription: boolean | null;
  useInterchangeRate: boolean | null;
  timeZoneCode: string;
  timeZone: Timezone;
  useTransAmountLimit: boolean;
  useACHTransAmountLimit: boolean;
  transAmountLimit: string;
  achTransAmountLimit: string;
  useDailyTransAmountLimit: boolean;
  useACHDailyTransAmountLimit: boolean;
  dailyTransAmountLimit: string;
  achDailyTransAmountLimit: string;
  useTechFee: boolean;
  useConvFee: boolean;
  legalEntityId: string;
  merchantAccountId: string;
  inBalancePlatform: boolean;
  balancePlatformAccountHolderId: string;
  migrated: boolean;
  migrationDate: string;
  hasMonthlyFee: boolean;
  monthlyFee: string;
  stores: AccountHolderStore[];
  verificationErrors: string;
  dateCreated: string;
};

export type AccountHolderRatePlan = {
  accountHolderId: string;
  paymentMethodType: string;
  fixedAmount: string;
  percentageValue: string;
  allowedMethod: boolean;
  surcharge: boolean;
};

export type Account = {
  accountHolderId: string;
  adyenAccountCode: string;
  id: string;
  locationCode: string;
  primaryAccount: boolean;
  status: string;
  bankUUID: string;
  bankAccountLast4: string;
  balancePlatformAccountId: string;
  payoutMethod: string;
};

export type PaymentMethod = {
  accountHolderId: string;
  allowedPaymentMethod: string;
};

export type AccountHolderNote = {
  note: string;
  userEmail: string;
  dateCreated: string;
};

export type FilterStatusType =
  | "complete"
  | "inprogress"
  | "all"
  | "kycneeded"
  | "";

export type AccountHoldersFilter = {
  accountHolderCode: string;
  status: FilterStatusType;
  showClosed?: boolean;
};

export type OnboardingStatus = "Complete" | "In Progress" | "KYC Needed";

export type ConvFee = "M" | "F" | "D" | "";

export type GetAccountHoldersListResponse = DefaultListResponse<AccountHolder>;

export type MerchantAccount = {
  merchantAccountId: string;
  merchantAccountName: string;
  merchantAccountType: string;
  merchantCurrency: string;
  processingFeeAccount: string;
  techFeeAccount: string;
  balancePlatformId: string;
  balancePlatformProcFeeAccount: string;
  balancePlatformTechFeeAcount: string;
  themeId: string;
  defaultForCountryCode: boolean;
  merchantCountryCode: string;
};

export type GetMerchantAccountsResponse = MerchantAccount[];

export type GetAccountConfigData = {
  edition: string;
  jrId: string;
  adyenAccountHolderCode: string;
  legalEntityType: string;
  companyName: string;
  companyEmail: string;
  contactFirstName: string;
  contactLastName: string;
  country: string;
  adyenStatus: string;
  jrPayStatus: string;
  legalBusinessName: string;
  merchantStatus: string;
  shopperStatement: string;
  techFeeRate: number;
  adyenMerchantAccount: string;
  adyenCaptureDelay: number;
  adyenPayoutSchedule: string;
  convFee: string;
  convFeeRate: number;
  paymentProcessingState: boolean;
  payoutState: boolean;
};

export type NoteContent = {
  fieldName: string;
  fromValue: string;
  toValue: string;
};

export type CreateAccountHolderNoteBody = {
  accountHolderCode: string;
  user: string;
  email: string;
  freeText?: string;
  noteContent?: NoteContent[];
};

export type CreateAccountHolderNoteBodyWithoutUserAndEmail = Omit<
  CreateAccountHolderNoteBody,
  "user" | "email"
>;

export type UpdateRatePlanBody = {
  accountHolderId: string;
  paymentMethodType: string;
  percentageValue: string;
  fixedAmount: string;
  allowedMethod: boolean;
  surcharge: boolean;
};

export type UpdateAllowedPaymentMethodsBody = {
  allowedPayments: {
    accountHolderId: string;
    allowedPaymentMethod: string;
  }[];
};

export type UpdateMerchantConfigBody = {
  accountHolderId: string;
  shopperStatement: string;
  techFeeRate: string;
  convFee: ConvFee;
  convFeeRate: string;
  adyenCaptureDelay: number;
  merchantStatus: string;
  timeZoneCode: string;
  useTransAmountLimit: boolean;
  useACHTransAmountLimit: boolean;
  transAmountLimit: string;
  achTransAmountLimit: string;
  useDailyTransAmountLimit: boolean;
  useACHDailyTransAmountLimit: boolean;
  dailyTransAmountLimit: string;
  achDailyTransAmountLimit: string;
  useTechFee: boolean;
  useConvFee: boolean;
  inBalancePlatform: boolean;
};

export type GetAccountConfigBody = {
  name: string;
  value: string[];
  type: FilterType;
};

export type BankAccountDetail = {
  accountNumber: number;
  accountType: string;
  bankAccountUUID: string;
};

export type GetBankAccountInfoResponse = {
  pspReference: string;
  accountHolderCode: string;
  bankAccountDetails: BankAccountDetail[];
};

export type CreateAccountBody = {
  accountHolderCode: string;
  locationCode: string;
  bankAccountUuid: string;
  bankAccountLast4: string;
};

export type CreateAccountResponse = {
  pspReference: string;
  accountCode: string;
  accountHoldercode: string;
  Message?: string;
  StatusCode?: number;
};

export type UpdateAccountBody = {
  accountHolderId: string;
  locationId: string;
  primaryAccount: boolean;
  bankUUID: string;
  bankAccountLast4: string;
};

export type GetOnboardingUrlBody = {
  accountHolderCode: string;
  returnUrl: string;
};

export type GetOnboardingUrlBalanceBody = {
  themeId: string;
  legalEntityId: string;
  returnUrl: string;
};

export type GetOnboardingUrlResponse = {
  resultCode: "Success" | "Failed";
  redirectUrl: string;
};

export type GetOnboardingUrlBalanceResponse = {
  redirectUrl: string;
};

export type AccountHolderUser = {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  status: boolean | null;
};

export type GetUserDetailsResponse = [
  {
    accountHolderCode: string;
    userInfo: AccountHolderUser[];
  }
];

export type UpdateAccountHolderBusinessContactBody = {
  accountHolderCode: string;
  contactFirstName: string;
  contactLastName: string;
  companyEmail: string;
};

export type UpdateAccountHolderEmailSubscriptionsBody = {
  accountHolderCode: string;
  daily: boolean;
  monthly: boolean;
};

export type UpdateAccountHolderInterhchangeFeeBody = {
  accountHolderCode: string;
  useInterchangeRate: boolean;
};

export async function createAccountHolder(
  body: CreateAccountHolderBody
): Promise<CreateAccountHolderResponse> {
  return httpCall<CreateAccountHolderResponse, CreateAccountHolderBody>(
    "POST",
    {
      url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/createAccountHolder`,
      errorMessage: "Unable to create account holder. Please try again.",
      body,
    }
  );
}

export async function createAccountHolderBalance(
  body: CreateAccountHolderBalance
) {
  const errorMessage = "Unable to create account holder. Please try again.";
  return httpCall<
    CreateAccountHolderBalanceResponse,
    CreateAccountHolderBalance
  >("POST", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/createAccountHolderBalance`,
    errorMessage,
    body,
    mapErrorMessageFn: (error) => {
      if (error.includes("Account Holder Already Exists.")) {
        return "Account create failed, an account with the ID already exists.";
      }
      return errorMessage;
    },
  });
}

function getAccountHoldersListOrders(
  currentOrders: SortingState
): SortingType[] {
  const currentOrderType = currentOrders?.at(0)?.desc
    ? OrderType.Desc
    : OrderType.Asc;

  if (currentOrderType) {
    if (currentOrders.find((order) => order.id === "onboardingStatus")) {
      return [
        { name: "adyenStatus", type: currentOrderType },
        { name: "jrPayStatus", type: currentOrderType },
      ];
    }

    if (currentOrders.find((order) => order.id === "locations")) {
      return [
        { name: "adyenStatus", type: currentOrderType },
        { name: "jrPayStatus", type: currentOrderType },
        {
          name: "numberOfAccounts",
          type:
            currentOrderType === OrderType.Asc ? OrderType.Desc : OrderType.Asc,
        },
      ];
    }

    if (currentOrders.find((order) => order.id === "contactFullName")) {
      return [{ name: "contactFirstName", type: currentOrderType }];
    }
  }

  return currentOrders.map((x) => ({
    name: x.id,
    type: x.desc ? OrderType.Desc : OrderType.Asc,
  }));
}

export type GetAccountHoldersListBody = AccountHoldersFilter & {
  pageNumber: number;
  pageSize: number;
  sort: SortingState;
};

export async function getAccountHoldersList({
  accountHolderCode,
  status,
  showClosed,
  pageNumber,
  pageSize,
  sort,
}: GetAccountHoldersListBody): Promise<GetAccountHoldersListResponse> {
  const body: DefaultPostBody = {
    pageNumber,
    pageSize,
    filters: [],
    orders: getAccountHoldersListOrders(sort),
  };

  AddSeachFilter(body, accountHolderCode);

  if (status && status !== "all") {
    body.filters.push({
      name: "adyenStatus",
      value: ["Active"],
      type: FilterType.Equals,
    });
    body.filters.push({
      name: "JrPayStatus",
      value: status === "complete" ? ["True"] : ["False"],
      type: FilterType.Equals,
    });

    if (status !== "complete") {
      body.filters.push({
        name: "Migrated",
        value: status === "kycneeded" ? ["True"] : ["False"],
        type: FilterType.Equals,
      });
    }
  }

  if (!showClosed) {
    body.filters.push({
      name: "MerchantStatus",
      value: ["Closed"],
      type: FilterType.NotEquals,
    });
  }

  return httpCall<GetAccountHoldersListResponse, DefaultPostBody>("POST", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/accountHolderList`,
    errorMessage: "Unable to load account holders list. Please try again.",
    body,
    mapFn: (data) => {
      const { pageElements: accountHolders } = data;

      return {
        ...data,
        pageElements: accountHolders.map((ah) => {
          ah.contactFullName = `${ah.contactFirstName} ${ah.contactLastName}`;
          ah.onboardingStatus = getOnboardingStatus(
            ah.adyenStatus,
            ah.jrPayStatus,
            ah.migrated
          );
          ah.migrationDate = formatISODateToLocal(
            ah.migrationDate,
            reportDateFormat
          );
          ah.dateCreated = formatISODateToLocal(
            ah.dateCreated,
            reportDateFormat
          );

          return ah;
        }),
      };
    },
  });
}

function AddSeachFilter(postBody: DefaultPostBody, searchStr: string) {
  if (searchStr) {
    switch (true) {
      case /^(CLASS|CARE)-/gi.test(searchStr):
      case /^\d+$/gi.test(searchStr):
        postBody.filters.push({
          name: "AdyenAccountHolderCode",
          value: [searchStr],
          type: FilterType.Contains,
        });
        break;
      case /@/gi.test(searchStr):
      case /(\.com)$/gi.test(searchStr):
        postBody.filters.push({
          name: "CompanyEmail",
          value: [searchStr],
          type: FilterType.Contains,
        });
        break;
      default:
        postBody.filters.push({
          name: "CompanyName",
          value: [searchStr],
          type: FilterType.Contains,
        });
        break;
    }
  }
}

export async function getMerchantAccounts(companyId: string) {
  return httpCall<GetMerchantAccountsResponse>("GET", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/getMerchantAccounts/${companyId}`.replace(
      "/accounts",
      "/settings"
    ),
    errorMessage: "Unable to load merchant accounts. Please try again.",
  });
}

export async function getMerchantAccountsSysConfig(companyId: string) {
  return httpCall<GetMerchantAccountsResponse>("POST", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/getSysConfig`.replace(
      "/accounts",
      "/settings"
    ),
    errorMessage: "Unable to load merchant accounts. Please try again.",
    body: {
      name: "companyId",
      value: [companyId],
      type: FilterType.Equals,
    },
  });
}

export async function getAccountConfig(accountHolderCode: string) {
  return httpCall<AccountHolder, GetAccountConfigBody>("POST", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/getAccountConfig`,
    errorMessage: "Unable to load the account configuration Please try again.",
    body: {
      name: "AdyenAccountHolderCode",
      value: [accountHolderCode],
      type: FilterType.Equals,
    },
    mapFn: (accountHolder) => ({
      ...accountHolder,
      techFeeRate: formatAmount(+accountHolder.techFeeRate),
      convFeeRate: formatAmount(+accountHolder.convFeeRate),
      onboardingStatus: getOnboardingStatus(
        accountHolder.adyenStatus,
        accountHolder.jrPayStatus,
        accountHolder.migrated
      ),
    }),
  });
}

export async function createAccountHolderNotes(
  body: CreateAccountHolderNoteBodyWithoutUserAndEmail
) {
  const currentUserAccount = await getCurrentUserAccountWithExtraFields();

  return httpCall<string, CreateAccountHolderNoteBody>("POST", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/createAccountHolderNotes`,
    errorMessage: "Unable to create account holder notes. Please try again.",
    responseType: "text",
    body: {
      ...body,
      user: currentUserAccount.name!,
      email: currentUserAccount.username,
    },
  });
}

export async function updateRatePlan(body: UpdateRatePlanBody) {
  return httpCall<string, UpdateRatePlanBody>("POST", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/update`.replace(
      "/accounts",
      "/ratePlans"
    ),
    errorMessage: "Unable to update rate plan. Please try again.",
    responseType: "text",
    body,
  });
}

export type ToggleRateplanSurchargeBody = {
  accountHolderId: string;
  surcharge: boolean;
};

export async function toggleRatePlanSurcharge(
  body: ToggleRateplanSurchargeBody
) {
  return httpCall<string, ToggleRateplanSurchargeBody>("POST", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/ToggleRateplanSurcharge`.replace(
      "/accounts",
      "/ratePlans"
    ),
    body,
    errorMessage: "Unable to update rate paln surcharge. Please try again.",
    responseType: "text",
  });
}

export async function updateAllowedPaymentMethods(
  body: UpdateAllowedPaymentMethodsBody
) {
  return httpCall<string, UpdateAllowedPaymentMethodsBody>("POST", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/update`.replace(
      "/accounts",
      "/AllowedPaymentMethods"
    ),
    errorMessage: "Unable to update allowed payment methods. Please try again.",
    responseType: "text",
    body,
  });
}

export async function updateMerchantConfig(body: UpdateMerchantConfigBody) {
  return httpCall<string, UpdateMerchantConfigBody>("POST", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/updateMerchantConfig`,
    errorMessage:
      "Unable to update merchant configuration info. Please try again.",
    responseType: "text",
    body,
  });
}

export async function getBankAccountInfo(accountHolderCode: string) {
  return httpCall<GetBankAccountInfoResponse>("GET", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/getBankAccountInfo/${accountHolderCode}`,
    errorMessage: "Unable to get bank account info. Please try again.",
  });
}

export async function createAccount(body: CreateAccountBody) {
  return httpCall<CreateAccountResponse, CreateAccountBody>("POST", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/createAccount`,
    errorMessage: "Unable to create client location. Please try again.",
    body,
  });
}

export async function updateAccount(body: UpdateAccountBody) {
  return httpCall<string, UpdateAccountBody>("POST", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/updateAccountDetails`,
    errorMessage: "Unable to update account. Please try again.",
    responseType: "text",
    body,
  });
}

export async function getOnboardingUrl(body: GetOnboardingUrlBody) {
  let defMessage = "Unable to get the onboarding url. Please try again.";
  return httpCall<GetOnboardingUrlResponse, GetOnboardingUrlBody>("POST", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/getOnboardingUrl`,
    body,
    errorMessage: "",
    mapErrorMessageFn: (error) => {
      const custMessage = JSON.parse(error).Message;
      return !isNullOrEmpty(custMessage) ? custMessage : defMessage;
    },
  });
}

export async function getOnboardingUrlBalance(
  body: GetOnboardingUrlBalanceBody
) {
  let defMessage = "Unable to get the onboarding url. Please try again.";
  return httpCall<GetOnboardingUrlBalanceResponse, GetOnboardingUrlBalanceBody>(
    "POST",
    {
      url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/getOnboardingUrlBalance`,
      body,
      errorMessage: "",
      mapErrorMessageFn: (error) => {
        const custMessage = JSON.parse(error).Message;
        return !isNullOrEmpty(custMessage) ? custMessage : defMessage;
      },
    }
  );
}

export async function getUserDetails(accountHolderCode: string = "") {
  const path = accountHolderCode ? `/${accountHolderCode}` : "";
  const userDetailsUrl = `${process.env.REACT_APP_ACCOUNTS_API_URI}/getUserDetails${path}`;

  return httpCall<GetUserDetailsResponse>("GET", {
    url: userDetailsUrl,
    errorMessage: "Unable to load user details. Please try again.",
  });
}

export type WebhookConfiguration = {
  edition: string;
  environment: string;
  url: string;
};

export type GetWebhookConfigurationResponse = WebhookConfiguration[];

export async function getWebhookConfiguration() {
  return httpCall<GetWebhookConfigurationResponse>("GET", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/getWebhookConfiguration`.replace(
      "/accounts",
      "/webhooks"
    ),
    errorMessage: "Unable to load webhook configuration. Please try again.",
  });
}

export async function updateAccountHolderBusinessContact(
  body: UpdateAccountHolderBusinessContactBody
) {
  return httpCall<string, UpdateAccountHolderBusinessContactBody>("POST", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/UpdateAccountHolderCompanyContact`,
    body,
    responseType: "text",
    errorMessage:
      "Unable to update the account holder business contact info. Please try again.",
  });
}

export async function updateAccountHolderEmailSubscriptions(
  body: UpdateAccountHolderEmailSubscriptionsBody
) {
  return httpCall<string, UpdateAccountHolderEmailSubscriptionsBody>("POST", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/updateAccountHolderEmailSubscriptions`,
    body,
    responseType: "text",
    errorMessage:
      "Unable to update the account holder email subscriptions info. Please try again.",
  });
}

export async function updateAccountHolderInterchangeFee(
  body: UpdateAccountHolderInterhchangeFeeBody
) {
  return httpCall<string, UpdateAccountHolderInterhchangeFeeBody>("POST", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/UpdateAccountHolderInterchangeFee`,
    body,
    responseType: "text",
    errorMessage:
      "Unable to update the account holder interchange fee. Please try again.",
  });
}

export type TimeZone = {
  country: string;
  code: string;
  name: string;
};

export type GetTimeZonesResponse = TimeZone[];

export type GetTimeZoneByIanaResponse = TimeZone;

export async function getTimeZonesByCountryCode(countryCode: string) {
  return httpCall<GetTimeZonesResponse>("GET", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/GetTimeZonesByCountryCode/${countryCode}`,
    errorMessage: "Unable to get time zones. Please try again.",
  });
}

export async function getTimeZoneByIana(body: string) {
  return httpCall<GetTimeZoneByIanaResponse>("POST", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/getTimeZoneByIana`,
    body,
    errorMessage: `Unable to get time zone for iana: ${body}. Please try again.`,
  });
}

export type MigrateAccountHolderResponse = {
  pspReference: string;
  resultCode: string;
};

export async function migrateAccountHolder(body: string) {
  return httpCall<MigrateAccountHolderResponse>("POST", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/MigrateAccountHolder`,
    body,
    errorMessage: `Unable to migrate account holder: ${body}. Please try again.`,
  });
}

export async function getThemeIdByMerchantAccount(merchantAccountId: string) {
  return httpCall<string>("GET", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/getThemeIdByMerchantAccount/${merchantAccountId}`.replace(
      "/accounts",
      "/settings"
    ),
    responseType: "text",
    errorMessage: "Unable to load theme id. Please try again.",
  });
}

export type BankAccount = {
  id: string;
  accountIdentifier: string;
};

export type GetBankAccountsByLegalEntityResponse = BankAccount[];

export async function getBankAccountsByLegalEntity(legalEntityId: string) {
  return httpCall<GetBankAccountsByLegalEntityResponse>("GET", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/getBankAccountsByLegalEntity/${legalEntityId}`,
    errorMessage: "Unable to get bank accounts. Please try again.",
  });
}

export type CreateAccountBalanceBody = {
  accountHolderCode: string;
  locationCode: string;
  bankAccountUuid: string;
  defaultCurrencyCode: string;
  description: string;
  bankAccountLast4: string;
};

export async function createAccountBalance(body: CreateAccountBody) {
  return httpCall<CreateAccountResponse, CreateAccountBody>("POST", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/createAccountBalance`,
    errorMessage: "Unable to create client location. Please try again.",
    body,
  });
}

export type CreateSweepBody = {
  balanceAccountId: string;
  counterParty: {
    transferInstrumentId: string;
  };
  triggerAmount: {
    currency: string;
    value: number;
  };
  currency: string;
  priorities: number[];
  category: number;
  schedule: {
    type: number;
  };
  type: number;
  status: number;
};

export type CreateSweepResponde = {};

export async function createSweep(body: CreateSweepBody) {
  return httpCall<CreateSweepResponde, CreateSweepBody>("POST", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/createSweep`,
    errorMessage: "Unable to create sweep. Please try again.",
    body,
  });
}

export type UserNotesBody = { accountHolderCode: string } & PageFilter &
  SortFilter;

export type GetAccountHolderNotesResponse =
  DefaultListResponse<AccountHolderNote>;

export async function getAccountHolderNotes(body: UserNotesBody) {
  return httpCall<GetAccountHolderNotesResponse, DefaultPostBody>("POST", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/GetAccountHolderNotes`,
    errorMessage: "Unable to get account holder notes. Please try again.",
    body: {
      pageNumber: body.pageNumber,
      pageSize: body.pageSize,
      filters: [
        {
          name: "accountHolderCode",
          value: [body.accountHolderCode],
          type: FilterType.Contains,
        },
      ],
      orders: body.sort.map((x) => ({
        name: x.id,
        type: x.desc ? OrderType.Desc : OrderType.Asc,
      })),
    },
    mapFn(data) {
      return {
        ...data,
        pageElements: data.pageElements.map((x) => ({
          ...x,
          dateCreated: formatISODateToLocal(x.dateCreated, longDateFormat),
        })),
      };
    },
  });
}

export type GetTaxFormBody = {
  accountHolderCode: string;
  reportYear: number;
};

export async function getTaxForm(body: GetTaxFormBody) {
  return httpCall<string, GetTaxFormBody>("POST", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/GetTaxForm`,
    errorMessage: `Unable to get tax form for account holder ${body.accountHolderCode} and year ${body.reportYear}. Please try again.`,
    body,
    responseType: "text",
  });
}

export type UpdateAccountHolderMonthlyFeeBody = {
  accountHolderId: string;
  hasMonthlyFee: boolean;
  monthlyFee: string;
};

export async function updateAccountHolderMonthlyFee(
  body: UpdateAccountHolderMonthlyFeeBody
) {
  return httpCall<string, UpdateAccountHolderMonthlyFeeBody>("POST", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/UpdateAccountHolderMonthlyFee`,
    errorMessage:
      "Unable to update account holder monthly fee. Please try again.",
    body,
    responseType: "text",
  });
}

export type GetLocationByAccountBody = {
  accountCodeList: string[];
};

export type GetLocationByAccountResponse = {
  accountCode: string;
  location: string;
}[];

export async function getLocationByAccount(body: GetLocationByAccountBody) {
  return httpCall<GetLocationByAccountResponse>("POST", {
    url: `${process.env.REACT_APP_ACCOUNTS_API_URI}/GetLocationByAccount`,
    body,
    errorMessage: `Unable to get location for accounts: ${body.accountCodeList}. Please try again.`,
  });
}
