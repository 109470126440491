import {
  createEmptyPayoutDetailItem,
  GetDepositDetailReportResponse,
} from "../services/transactionsService";
import { formatAmount } from "./currency";
import {
  formatISODateToLocal,
  longDateFormat,
  reportNameDateFormat,
} from "./dateTime";
import { downloadPayoutDetailCsv } from "./transaction";

export function generatePayoutCsv(
  data: GetDepositDetailReportResponse,
  reportDate: string,
  locationName: string
) {
  const reportName = `jackrabbit_pay_deposit_${locationName}_${formatISODateToLocal(
    reportDate,
    reportNameDateFormat
  )}.csv`;

  let items = data.details.map((x) => ({
    ...x,
    date: formatISODateToLocal(x.bookingDate, longDateFormat),
    amount: formatAmount(x.amount).replaceAll(",", ""),
    transactionCreatedDate: formatISODateToLocal(
      x.transactionCreatedDate,
      longDateFormat
    ),
  }));
  const rollingBalance = data.rollingBalance ? Number(data.rollingBalance) : 0;
  const payoutAmount = data.payoutAmount ? Number(data.payoutAmount) : 0;

  // Create a default payout detail item with the new date property
  const defaultPayoutDetailItem = {
    ...createEmptyPayoutDetailItem(),
    date: "",
  };

  // always add the sum of the amount of the payout detail items
  items.push({
    ...defaultPayoutDetailItem,
    recordType: "Total",
    amount: formatAmount(
      data.details
        .reduce((sum, detail) => sum + Number(detail.amount), 0)
        .toFixed(2)
    ).replaceAll(",", ""),
  });

  // If there is a rolling balance, add a row for the rolling balance (reserve amount that hasn't been paid out or released amount)
  if (rollingBalance !== 0) {
    items.push({
      ...defaultPayoutDetailItem,
      recordType:
        rollingBalance < 0
          ? "Pending Reserve Deduction"
          : rollingBalance > 0
          ? "Reserve Disbursement"
          : "",
      amount: formatAmount(data.rollingBalance).replaceAll(",", ""),
    });
  }

  // always add the payout record amount row
  items.push({
    ...defaultPayoutDetailItem,
    recordType: "Payout",
    amount: formatAmount((0 - payoutAmount).toString()).replaceAll(",", ""),
  });

  // If there is a rolling balance, add a row with a footnote explaining the pending reserve deduction or reserve disbursement
  if (rollingBalance !== 0) {
    // Add an empty row to separate the payout record amount row and the footnote row
    items.push(defaultPayoutDetailItem);

    items.push({
      ...defaultPayoutDetailItem,
      pspReference:
        rollingBalance < 0
          ? "* A pending refund or chargeback has resulted in a reserved deduction for the upcoming transaction(s)."
          : "* An amount previously reserved for refunds or chargebacks is now available for disbursement and has been included in the payout.",
    });
  }

  downloadPayoutDetailCsv(items, reportName);
}
