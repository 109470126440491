import { RatePlan, RatePlanType } from "./RatePlans";
import { AccountHolderRatePlan } from "../../services/accountService";
import RatePlanList from "./RatePlanList";
import MonthlyFee from "./MonthlyFee";
import { displayWalletRates } from "../../utils/walletRates";

type Props = {
  accountHolderCode: string;
  accountHolderId: string;
  accountHolderRatePlans: AccountHolderRatePlan[];
  useInterchangeRate: boolean;
  hasMonthlyFee: boolean;
  monthlyFee: string;
  ratePlanType: RatePlanType;
  isLoading: boolean;
};

// const ratePlans: RatePlan[] = [{ id: "interchange", label: "Rate" }];
let ratePlans: RatePlan[] = [
  { id: "visa", label: "Visa" },
  { id: "mc", label: "Mastercard" },
  { id: "amex", label: "AMEX" },
  { id: "discover", label: "Discover" },
  { id: "ach", label: "ACH" },
];

ratePlans = displayWalletRates(ratePlans);

function InterchangePlusPlan({
  accountHolderCode,
  accountHolderId,
  accountHolderRatePlans,
  useInterchangeRate,
  hasMonthlyFee,
  monthlyFee,
  ratePlanType,
  isLoading,
}: Props): JSX.Element {
  return (
    <div className="flex w-full flex-col flex-wrap">
      <RatePlanList
        accountHolderCode={accountHolderCode}
        accountHolderId={accountHolderId}
        accountHolderRatePlans={accountHolderRatePlans}
        ratePlans={ratePlans}
        ratePlanType={ratePlanType}
        isLoading={isLoading}
      />
      <MonthlyFee
        accountHolderCode={accountHolderCode}
        accountHolderId={accountHolderId}
        hasMonthlyFee={hasMonthlyFee}
        monthlyFee={monthlyFee}
      />
    </div>
  );
}

export default InterchangePlusPlan;
