export const emailRegexp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const usPostalCodeRegexp = /^\d{5}(?:-\d{4})?$/;
export const caPostalCodeRegexp =
  /^(?:[ABCEGHJ-NPRSTVXY]\d[A-Z][ ]?\d[A-Z]\d)$/i;
export const auAndNzPostalCodeRegexp = /^[0-9]{4}$/;

export const usAndCaPhoneRegexp =
  /^(?:\+1)?\s?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
export const auPhoneRegexp = /^\d{4} \d{4}$/;

export const accountCreditDescriptionRegexp = /^([a-zA-Z0-9-?:().,'+ ]+)$/;
