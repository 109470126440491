import { useEffect, useRef, useState } from "react";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Modal from "../../components/Modal";
import useCreateTransferFundsMutation from "../../hooks/data/mutations/useCreateTransferFundsMutation";
import { useSnackbar } from "../../components/Snackbar";
import Spinner from "../../components/Spinner";
import Textarea from "../../components/Textarea";
import { accountCreditDescriptionRegexp } from "../../utils/regexp";
import useMerchantAccountsSysConfigQuery from "../../hooks/data/queries/useMerchantAccountsSysConfigQuery";
import useCreateDebitTransferMutation from "../../hooks/data/mutations/useCreateDebitTransferMutation";
import Select from "../../components/Select";
import { Account } from "../../services/accountService";
import { CreateTransferFundsResponse } from "../../services/transactionsService";
import { camelCaseToNormal, capitalize } from "../../utils/string";

const operationTypes = ["Credit", "Debit"] as const;
type OperationType = typeof operationTypes[number];

type Props = {
  isOpen: boolean;
  onClose: () => void;
  accountHolderCode: string;
  merchantAccountId: string;
  primaryAccountAccountId: string;
  accounts: Account[];
};

function AccountCreditDebitModal({
  isOpen,
  onClose,
  accountHolderCode,
  merchantAccountId,
  primaryAccountAccountId,
  accounts,
}: Props): JSX.Element {
  const [amountValue, setAmountValue] = useState("");
  const [description, setDescription] = useState("");
  const [operationType, setOperationType] = useState<OperationType>("Credit");
  const typeRef = useRef<HTMLSelectElement | null>(null);
  const { mutate: mutateCredit, isLoading: isLoadingCredit } =
    useCreateTransferFundsMutation();
  const { mutate: mutateDebit, isLoading: isLoadingDebit } =
    useCreateDebitTransferMutation();

  const isLoading = isLoadingCredit || isLoadingDebit;

  const companyId = "JackRabbitTech"; // TODO: Harcoded for now
  const { data: merchantAccounts } =
    useMerchantAccountsSysConfigQuery(companyId);
  const merchantAccount = merchantAccounts?.find(
    (x) => x.merchantAccountId === merchantAccountId
  );

  const currency = merchantAccount?.merchantCurrency ?? "USD";
  const descriptionIsValid = description.match(accountCreditDescriptionRegexp);

  const { openSnackbar } = useSnackbar({
    duration: 30000,
    position: "top",
  });

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => typeRef.current?.focus(), 0);
    }
  }, [isOpen]);

  function applyOperation() {
    function onSuccess(data: CreateTransferFundsResponse) {
      if (data.status === "refused") {
        const reason = camelCaseToNormal(data.reason).toLowerCase();
        openSnackbar(`${operationType} refused. Reason: ${reason}.`, {
          type: "error",
        });
        typeRef.current?.focus();
      } else {
        openSnackbar(`Account ${operationType} was successfully created.`, {
          type: "success",
        });
        setAmountValue("");
        setDescription("");
        setOperationType("Credit");
        onClose();
      }
    }

    function onError() {
      openSnackbar(
        "Failed to update create account credit. Please try again.",
        {
          type: "error",
        }
      );
      typeRef.current?.focus();
    }

    if (operationType === "Credit") {
      mutateCredit(
        {
          amount: {
            value: amountValue,
            currency,
          },
          notes: description,
          counterparty: primaryAccountAccountId,
          merchantAccountId: merchantAccountId,
          accountHolderCode,
        },
        {
          onSuccess,
          onError,
        }
      );
    } else {
      mutateDebit(
        {
          amount: {
            value: amountValue,
            currency,
          },
          notes: description,
          sourceBalanceAccount: primaryAccountAccountId,
          merchantAccountId: merchantAccountId,
          accountHolderCode,
        },
        {
          onSuccess,
          onError,
        }
      );
    }
  }

  const disableSaveButton =
    isLoading ||
    amountValue === "" ||
    Number(amountValue) <= 0 ||
    description === "" ||
    !descriptionIsValid;

  return (
    <Modal
      isOpen={isOpen}
      title="Account Credit/Debit"
      panelClasses="!w-80"
      titleClasses="text-left"
      onClose={() => {
        setAmountValue("");
        setDescription("");
        onClose();
      }}
    >
      <div className="mt-10 flex flex-col gap-y-6">
        <Select
          id="operationType"
          label="Type"
          options={operationTypes.map((x) => ({
            value: x,
            label: x,
          }))}
          value={operationType}
          onChange={(e) =>
            setOperationType(operationTypes[e.target.selectedIndex - 1])
          }
          ref={typeRef}
        />
        <Input
          type="number"
          value={amountValue}
          onChange={(e) => setAmountValue(e.target.value)}
          label="Amount"
          adornmentText={currency}
          errorMessage={
            amountValue && Number(amountValue) <= 0
              ? "Amount should be greater than zero."
              : ""
          }
          className="mb-4"
        />
        <Textarea
          label="Description"
          rows={2}
          maxLength={80}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          errorMessage={
            description && !descriptionIsValid
              ? `There are some invalid characters. Valid characters are: [a-z] [A-Z] [0-9] / - ?
: ( ) . , ' + Space`
              : ""
          }
          errorMessageContainerProps={{
            className: "static",
          }}
        />
        <Button
          onClick={applyOperation}
          disabled={disableSaveButton}
          icon={isLoading && <Spinner className="text-gray-400" />}
        >
          Enter
        </Button>
      </div>
    </Modal>
  );
}

export default AccountCreditDebitModal;
