import useCurrentUserAccount from "../../hooks/data/queries/useCurrentUserAccount";
import CurrencyDollarIcon from "@heroicons/react/24/outline/CurrencyDollarIcon";
import ClipboardDocumentListIcon from "@heroicons/react/24/outline/ClipboardDocumentListIcon";
import UserGroupIcon from "@heroicons/react/24/outline/UserGroupIcon";
import Cog6ToothIcon from "@heroicons/react/24/outline/Cog6ToothIcon";
import BanknotesIcon from "@heroicons/react/24/outline/BanknotesIcon";
import BuildingLibraryIcon from "@heroicons/react/24/outline/BuildingLibraryIcon";
import WrenchScrewdriverIcon from "@heroicons/react/24/outline/WrenchScrewdriverIcon";
import HasAccess, { csrRoles } from "../../components/HasAccess";
import HomeIcon from "./HomeIcon";
import { getTransactionsPageDefaultUrl } from "../../utils/transaction";
import { getOnboardingPageDefaultUrl } from "../../utils/accounts";
import { logPageVisit } from "../../utils/appInsightsLogger";
import { accountConfigAtom } from "../../atoms";
import { useAtom } from "jotai";

const displayCapital: boolean =
  (process.env.REACT_APP_FF_DISPLAY_CAPITAL?.toLowerCase() ?? "true") ===
  "true";

function Home(): JSX.Element {
  const { data: currentUserAccount } = useCurrentUserAccount();
  const [accountConfigData] = useAtom(accountConfigAtom);

  const showCapital =
    currentUserAccount?.type === "Customer" &&
    accountConfigData?.country === "US" &&
    displayCapital;

  const logVisit = () => {
    logPageVisit("Transactions", currentUserAccount?.username);
  };

  return (
    <div className="flex items-center justify-center px-6">
      <div className="mt-32 flex w-full flex-wrap justify-around gap-x-10 gap-y-6 lg:w-1/3">
        <HomeIcon
          logPageVisit={logVisit}
          label="Transactions"
          to={getTransactionsPageDefaultUrl()}
          icon={<CurrencyDollarIcon className="w-24 text-primary-700" />}
        />
        <HomeIcon
          label="Reports"
          to="/reports"
          icon={<ClipboardDocumentListIcon className="w-24 text-primary-700" />}
        />
        <HasAccess allowedRoles={[...csrRoles]}>
          <HomeIcon
            label="Clients"
            to={getOnboardingPageDefaultUrl()}
            icon={<UserGroupIcon className="w-24 text-primary-700" />}
          />
        </HasAccess>
        <HomeIcon
          label={currentUserAccount?.type === "CSR" ? "System" : "Settings"}
          to={
            currentUserAccount?.type === "CSR"
              ? "/system-settings"
              : "/customer-settings"
          }
          icon={<Cog6ToothIcon className="w-24 text-primary-700" />}
        />
        <HomeIcon
          label="Payout"
          to={
            currentUserAccount?.type === "CSR"
              ? "/payout"
              : `/payout/${currentUserAccount?.accountHolderCode}`
          }
          icon={<BanknotesIcon className="w-24 text-primary-700" />}
        />
        {showCapital && (
          <HomeIcon
            label="Capital Lending"
            to={`/capital/${currentUserAccount?.accountHolderCode}`}
            icon={<BuildingLibraryIcon className="w-24 text-primary-700" />}
          />
        )}
        <HasAccess allowedRoles={["ADMIN"]}>
          <HomeIcon
            label="System Tools"
            to={"/system-tools"}
            icon={<WrenchScrewdriverIcon className="w-24 text-primary-700" />}
          />
        </HasAccess>
      </div>
    </div>
  );
}

export default Home;
