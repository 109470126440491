import { Transition } from "@headlessui/react";
import React, { useRef } from "react";
import Checkbox from "../../components/Checkbox";
import Select from "../../components/Select";
import { Account } from "../../services/accountService";
import { BankAccountItem } from "../../hooks/data/queries/useNormalizedBankAccountsQuery";

type Props = {
  show: boolean;
  location: Account | null;
  onLocationChange: (location: Account) => void;
  bankAccountDetails: BankAccountItem[];
  migrated: boolean;
};

function EditLocation({
  show,
  location,
  onLocationChange,
  bankAccountDetails,
  migrated,
}: Props): JSX.Element {
  const primaryAccountCheckboxRef = useRef<HTMLInputElement>(null);
  const isPrimaryAccount = location?.primaryAccount ?? false;

  const getAdyenBalanceDetailUrl = (accountId: string | undefined) =>
    process.env.REACT_APP_ADYEN_BALANCE_DETAIL_URL_TEMPLATE?.replace(
      "{accountid}",
      accountId ?? ""
    ) ?? "";

  return (
    <Transition
      show={show}
      className="ml-1 flex flex-col gap-y-4 border-l-2 border-gray-300 p-2 pl-4"
      enter="duration-300 transition-all"
      enterFrom="opacity-0 max-h-0"
      enterTo="opacity-100 max-h-60"
      leave="duration-300 transition-all"
      leaveFrom="opacity-100 max-h-60"
      leaveTo="opacity-0 max-h-0"
      afterEnter={() => {
        primaryAccountCheckboxRef?.current?.focus();
      }}
    >
      <div className="flex justify-between gap-x-2">
        <div className="font-medium">Location Name</div>
        <div>{location?.locationCode}</div>
      </div>
      <div className="flex justify-between gap-x-2">
        <div className="font-medium">Location Id</div>
        <div className="text-sm">
          <a
            href={getAdyenBalanceDetailUrl(
              migrated
                ? location?.balancePlatformAccountId
                : location?.adyenAccountCode
            )}
            target="adyenSite"
            title="View account in Adyen Console"
            className="text-primary underline"
          >
            {migrated
              ? location?.balancePlatformAccountId
              : location?.adyenAccountCode}
          </a>
        </div>
      </div>
      <div className="flex justify-between gap-x-2">
        <div className="font-medium">Primary Account</div>
        <Checkbox
          ref={primaryAccountCheckboxRef}
          id="locationPrimaryAccount"
          checked={isPrimaryAccount}
          disabled={isPrimaryAccount}
          onChange={() => {
            if (location) {
              onLocationChange({
                ...location,
                primaryAccount: !location?.primaryAccount,
              });
            }
          }}
        />
      </div>
      <div className="flex justify-between gap-x-2">
        <div className="font-medium">Bank UUID</div>
        <Select
          id="locationBankUUID"
          value={location?.bankUUID ?? ""}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            const bankAccountDetail = bankAccountDetails.find(
              (d) => d.id === e.target.selectedOptions[0].value
            );
            if (bankAccountDetail && location) {
              onLocationChange({
                ...location,
                bankUUID: bankAccountDetail.id,
                bankAccountLast4: bankAccountDetail.last4,
              });
            }
          }}
          options={bankAccountDetails.map((detail) => ({
            value: detail.id,
            label: detail.label,
          }))}
          containerProps={{
            className: "w-1/2",
          }}
        />
      </div>
      <div className="flex justify-between gap-x-2">
        <div className="font-medium">Status</div>
        <div>{location?.status}</div>
      </div>
    </Transition>
  );
}

export default EditLocation;
