import {
  getLocationByAccount,
  GetLocationByAccountBody,
  GetLocationByAccountResponse,
} from "../../../services/accountService";
import { useQuery } from "@tanstack/react-query";

export default function useGetLocationByAccountQuery(
  body: GetLocationByAccountBody
) {
  return useQuery<GetLocationByAccountResponse, string>(
    ["getLocationByAccount", body],
    () => getLocationByAccount(body)
  );
}
