import { AccountHolderRatePlan } from "../../services/accountService";
import { RatePlan, RatePlanType } from "./RatePlans";
import RatePlanList from "./RatePlanList";
import { displayWalletRates } from "../../utils/walletRates";

type Props = {
  accountHolderCode: string;
  accountHolderId: string;
  accountHolderRatePlans: AccountHolderRatePlan[];
  useInterchangeRate: boolean;
  ratePlanType: RatePlanType;
  isMasterSurchargeEnabled: boolean;
  isLoading: boolean;
};

let ratePlans: RatePlan[] = [
  { id: "visa", label: "Visa" },
  { id: "mc", label: "Mastercard" },
  { id: "amex", label: "AMEX" },
  { id: "discover", label: "Discover" },
  { id: "ach", label: "ACH" },
];

ratePlans = displayWalletRates(ratePlans);

function FlatRatePlans({
  accountHolderCode,
  accountHolderId,
  accountHolderRatePlans,
  useInterchangeRate,
  ratePlanType,
  isMasterSurchargeEnabled,
  isLoading,
}: Props): JSX.Element {
  return (
    <div className="flex flex-shrink-0 flex-col flex-wrap">
      <div className="mb-3">
        <RatePlanList
          accountHolderCode={accountHolderCode}
          accountHolderId={accountHolderId}
          ratePlans={ratePlans}
          accountHolderRatePlans={accountHolderRatePlans}
          ratePlanType={ratePlanType}
          isMasterSurchargeEnabled={isMasterSurchargeEnabled}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

export default FlatRatePlans;
