import { RatePlan } from "../pages/customerSettings/RatePlans";

export function displayWalletRates(basePlans: RatePlan[]) {
  let ratePlans = [...basePlans];

  if (
    (process.env.REACT_APP_FF_DISPLAY_GOOGLEPAY?.toLowerCase() ?? "false") ===
    "true"
  ) {
    ratePlans = [...ratePlans, { id: "googlepay", label: "Google Pay" }];
  }

  if (
    (process.env.REACT_APP_FF_DISPLAY_APPLEPAY?.toLowerCase() ?? "false") ===
    "true"
  ) {
    ratePlans = [...ratePlans, { id: "applepay", label: "Apple Pay" }];
  }

  if (
    (process.env.REACT_APP_FF_DISPLAY_PAYPAL?.toLowerCase() ?? "false") ===
    "true"
  ) {
    ratePlans = [...ratePlans, { id: "paypal", label: "PayPal" }];
  }

  return ratePlans;
}
