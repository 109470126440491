import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { Route, Routes } from "react-router-dom";
import Main from "./components/Main";
import LoadingFullScreen from "./components/LoadingFullScreen";
import {
  Home,
  OnboardingSummary,
  Transactions,
  Transaction,
  SystemSettings,
  CustomerSettings,
  Welcome,
  Reports,
  DailyReportDetail,
  NotFound,
  MonthlyReportDetail,
  TenNinetyNineKReportDetail,
} from "./pages";
import ErrorFullScreen from "./components/ErrorFullScreen";
import { loginRequest } from "./authConfig";
import DisabledUser from "./pages/disabledUser/DisabledUser";
import DepositsReport from "./pages/reports/DepositsReport";
import Payout from "./pages/Payout";
import Capital from "./pages/Capital";
import SystemTools from "./pages/system-tools/SystemTools";

type Props = {
  msalInstance: PublicClientApplication;
};

function App({ msalInstance }: Props): JSX.Element {
  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        authenticationRequest={{
          ...loginRequest,
        }}
        interactionType={InteractionType.Redirect}
        loadingComponent={LoadingFullScreen}
        errorComponent={ErrorFullScreen}
      >
        <Routes>
          <Route path="/" element={<Main />}>
            <Route path="/" element={<Home />} />
            <Route path="onboarding" element={<OnboardingSummary />} />
            <Route path="transactions" element={<Transactions />} />
            <Route path="payments" element={<Transactions />} />
            <Route path="exceptions" element={<Transactions />} />
            <Route path="payments/:pspReference" element={<Transaction />} />
            <Route path="system-settings" element={<SystemSettings />} />
            <Route path="customer-settings" element={<CustomerSettings />} />
            <Route
              path="customer-settings/:accountHolderCode"
              element={<CustomerSettings />}
            />
            <Route path="reports" element={<Reports />} />
            <Route
              path="reports/daily/:accountHolderCode/:reportDate"
              element={<DailyReportDetail />}
            />
            <Route
              path="reports/monthly/:accountHolderCode/:reportDate"
              element={<MonthlyReportDetail />}
            />
            <Route
              path="reports/deposits/:accountHolderCode/:reportDate"
              element={<DepositsReport />}
            />
            <Route
              path="reports/1099-K/:accountHolderCode/:reportDate"
              element={<TenNinetyNineKReportDetail />}
            />
            <Route path="payout" element={<Payout />} />
            <Route path="payout/:accountHolderCode" element={<Payout />} />
            <Route path="capital/:accountHolderCode" element={<Capital />} />
            <Route path="/system-tools" element={<SystemTools />} />
            <Route path="*" element={<NotFound />}></Route>
          </Route>
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/disabledUser" element={<DisabledUser />} />
        </Routes>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

export default App;
