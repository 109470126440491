import countries from "../data/countries.json";
import states from "../data/states.json";
import {
  auPhoneRegexp,
  auAndNzPostalCodeRegexp,
  caPostalCodeRegexp,
  usAndCaPhoneRegexp,
  usPostalCodeRegexp,
} from "./regexp";

type CountryInfo = {
  name: string;
  states: { value: string; label: string }[];
  phonePrefix: string;
  phoneRegexp: RegExp;
  getFormattedPhoneNumber: (phoneNumber: string) => string;
  postalCodeRegexp: RegExp;
};

export function getCountryInfo(countryCode: string): CountryInfo {
  const countryName =
    countries.find((x) => x.value === countryCode)?.label ?? "";

  return {
    name: countryName,
    states: states.filter((x) => x.country === countryCode),
    phonePrefix: getPhonePrefix(countryCode),
    phoneRegexp: getPhoneRegexp(countryCode),
    getFormattedPhoneNumber: (phoneNumber: string) =>
      getFormattedPhoneNumber(phoneNumber, countryCode),
    postalCodeRegexp: getPostalCodeRegexp(countryCode),
  };
}

function getFormattedPhoneNumber(number: string, countryCode: string): string {
  if (!number) return number;

  const phoneNumber = number.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;

  switch (countryCode) {
    case "US":
    case "CA":
      if (phoneNumberLength < 4) return phoneNumber;
      if (phoneNumberLength < 7) {
        return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3)}`;
      }
      return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(
        3,
        6
      )} ${phoneNumber.slice(6, 10)}`;

    case "AU":
      if (phoneNumberLength < 5) return phoneNumber;
      return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(4, 8)}`;
  }

  return number;
}

export function getPhonePrefix(countryCode: string): string {
  switch (countryCode) {
    case "US":
    case "CA":
      return "+1";
    case "AU":
      return "+61";
    case "NZ":
      return "+64";
  }
  return "";
}

function getPhoneRegexp(countryCode: string) {
  switch (countryCode) {
    case "US":
    case "CA":
      return usAndCaPhoneRegexp;
    case "AU":
      return auPhoneRegexp;
  }
  return /(?:)/;
}

function getPostalCodeRegexp(countryCode: string) {
  switch (countryCode) {
    case "US":
      return usPostalCodeRegexp;
    case "CA":
      return caPostalCodeRegexp;
    case "AU":
    case "NZ":
      return auAndNzPostalCodeRegexp;
  }
  return /(?:)/;
}
