import HasAccess from "../../components/HasAccess";
import Error from "../../components/Error";

function SystemTools(): JSX.Element {
  return (
    <HasAccess
      allowedRoles={["ADMIN"]}
      notAllowedComponent={
        <Error message="You're not allowed to view this page." />
      }
    >
      <h3 className="mb-4 text-3xl">System Tools</h3>
    </HasAccess>
  );
}

export default SystemTools;
